import { z } from 'zod';
import { Link } from 'react-router-dom';

import { AuthStatus, useAuth } from 'auth';
import Form from 'components/Form/Form';
import InputField from 'components/Form/InputField';
import Icon from 'components/icon';
import SubmitButton from 'components/Form/SubmitButton';
import ProductImage from 'my-account/images/product-01.jpg';
import useDocumentTitle from 'hooks/useDocumentTitle';

const LoginSchema = z.object({
  username: z.string().email().nonempty(),
  password: z.string().nonempty(),
});

export function LoginForm() {
  const auth = useAuth();

  const isLoggingIn = auth.status === AuthStatus.LoggingIn || auth.loginMutationState.isLoading;

  return (
    <Form
      schema={LoginSchema}
      initialValues={{ username: '', password: '' }}
      onSubmit={async (data) => {
        await auth.login(data.username, data.password);
      }}
      className="c-form-group c-form-group--sm c-form-group--ver u-ml-auto u-mr-auto"
      hasFloatingLabels
    >
      <div className="o-row o-row--no-gutters c-form-group__dividers u-flex-grow">
        <div className="o-col-12">
          <InputField
            name="username"
            type="email"
            label="Email address:"
            icon={<Icon name="envelope" className="o-svg-icon" />}
            className="c-form-element--high c-form-element--style-line"
            disabled={isLoggingIn}
            inputProps={{
              autoComplete: 'email',
            }}
          />
        </div>
        <div className="o-col-12">
          <InputField
            name="password"
            type="password"
            label="Password:"
            icon={<Icon name="keyhole" className="o-svg-icon" />}
            className="c-form-element--high c-form-element--style-line"
            disabled={isLoggingIn}
            inputProps={{
              autoComplete: 'current-password',
            }}
          />
        </div>
      </div>
      <div className="c-form-footer">
        <SubmitButton isLoading={isLoggingIn} isFull>
          Log in
        </SubmitButton>
      </div>
    </Form>
  );
}

export default function LoginPage() {
  useDocumentTitle('Login');

  return (
    <section
      className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@md c-block--spacing-b-extra-small c-block--spacing-b-small@md c-block--bg-light2 c-block--hero c-block--height-semifull@xs c-block--bg-image"
      style={{ backgroundImage: `url(${ProductImage})` }}
    >
      <div className="o-container-fluid">
        <div className="o-row u-flex-grow u-items-center">
          <div className="o-col-12">
            <div className="c-block__header c-block__header--xl c-block__header--large u-items-center u-text-center">
              <h1 className="c-title--extra-large">
                Welcome to the
                <br />
                My Symmons Portal!
              </h1>
              <div className="c-hero__desc">
                <p>Log in with your Portal credentials</p>
              </div>
            </div>
            <LoginForm />
            <div className="u-justify-center u-text-center">
              <p className="u-mb-0">
                <Link to="/auth/forgot-password" className="c-link-cta-basic c-link-cta--small">
                  <span>Forgot your password?</span>
                </Link>
              </p>
              <div className="c-form-group c-form-group--sm u-mx-auto u-mb-spacer-base">
                <Link to="/auth/salesforce" className="c-button c-button--dark c-button--full">
                  <span>Salesforce log in</span>
                </Link>
              </div>
            </div>
            <div className="c-info c-info--sm u-mx-auto">
              <div className="c-info__header">
                <div className="c-info__icon u-text-secondary">
                  <Icon name="info" className="o-svg-icon" />
                </div>
                <p>
                  First time here?{' '}
                  <Link
                    to="/auth/request-account"
                    className="c-link-cta-basic c-link-cta--small u-mb-0 u-ml-spacer-base-small@sm"
                  >
                    <span>Request an account</span>
                    <Icon name="arrow" className="o-svg-icon o-svg-right" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40">
        <Icon name="pattern" className="o-svg-icon" />
      </div>
      <div className="c-block__overlay c-block__overlay--light2 c-block__overlay--opacity-85"></div>
    </section>
  );
}
